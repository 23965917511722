#home {
  height: 900px;
}
.content {
  margin-top: 300px;
}
#home .contents {
  margin-left: 80px;
}
#home .container {
  margin-top: 120px;
  text-align: center;
  justify-content: center;
}
#home button {
  margin-top: 50px;
  height: 73px;
  width: 300px;
}

/*  import google fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&family=Ubuntu:wght@400;500;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}
html {
  scroll-behavior: smooth;
}

/* custom scroll bar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* all similar content styling codes */
section {
  padding: 100px 0;
}

section .title {
  position: relative;
  text-align: center;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 60px;
  padding-bottom: 20px;
  font-family: "Ubuntu", sans-serif;
}
section .title::before {
  position: absolute;
  bottom: 0px;
  left: 50%;
  width: 180px;
  height: 3px;
  background: #111;
  transform: translateX(-50%);
}
section .title::after {
  position: absolute;
  bottom: -8px;
  left: 50%;
  font-size: 20px;
  color: #fff;
  padding: 0 5px;
  background: #fff;
  transform: translateX(-50%);
}

/* menu btn styling */
.menu-btn {
  color: #fff;
  font-size: 23px;
  cursor: pointer;
  display: none;
}
.scroll-up-btn {
  position: fixed;
  height: 45px;
  width: 42px;
  background: #5bc0de;
  right: 30px;
  bottom: 10px;
  text-align: center;
  line-height: 45px;
  color: #fff;
  z-index: 9999;
  font-size: 30px;
  border-radius: 6px;
  border-bottom-width: 2px;
  cursor: pointer;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease;
}
.scroll-up-btn.show {
  bottom: 30px;
  opacity: 1;
  pointer-events: auto;
}
.scroll-up-btn:hover {
  filter: brightness(90%);
}

@media (max-width: 500px) {
  .scroll-up-btn {
    right: 15px;
    bottom: 15px;
    height: 38px;
    width: 35px;
    font-size: 23px;
    line-height: 38px;
  }
}

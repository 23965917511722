.navbar {
  height: 70px;
}
.navbar .logo_h1 {
  padding-top: 15px;
  color: rgb(122, 122, 122);
}
.navbar .logo_h1_span {
  color: red;
}

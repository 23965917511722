.image {
  height: 700px;
}
.img {
  margin-top: 70px;
}
.text {
  margin-top: 100px;
}
.heading {
  text-align: center;
}
.body-text {
  margin-top: 10px;
}
